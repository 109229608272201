.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container {
  min-height: 100vh;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-p11440311h-g-m-home-adminmember-viewgroupdetailstabclon {
  width: 100%;
  height: 1100px;
  display: flex;
  /* overflow: hidden; */
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  background-color: var(--dl-color-gray-white);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-header {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: space-between;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-primary {
  top: 0px;
  left: 0px;
  width: 839px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-navigation-logo {
  top: 0px;
  left: 0px;
  width: 200px;
  height: 56px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-logo-background {
  top: 0px;
  left: 0px;
  width: 193px;
  height: 56px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text {
  top: 16px;
  left: 83px;
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-simple {
  top: 9px;
  left: 12px;
  width: 41px;
  height: 39px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-link-items {
  top: 0px;
  left: 200px;
  width: 639px;
  height: 56px;
  display: flex;
  padding: 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links {
  top: 16px;
  left: 0px;
  width: 95px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text002 {
  top: 0px;
  left: 0px;
  width: 71px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text003 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand {
  top: 0px;
  left: 71px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links1 {
  top: 16px;
  left: 119px;
  width: 94px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text005 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text006 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand1 {
  top: 0px;
  left: 70px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links2 {
  top: 16px;
  left: 237px;
  width: 104px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text008 {
  top: 0px;
  left: 0px;
  width: 80px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text009 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand2 {
  top: 0px;
  left: 80px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links3 {
  top: 16px;
  left: 365px;
  width: 103px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text011 {
  top: 0px;
  left: 0px;
  width: 79px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text012 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand3 {
  top: 0px;
  left: 79px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links4 {
  top: 16px;
  left: 492px;
  width: 87px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text014 {
  top: 0px;
  left: 0px;
  width: 63px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text015 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand4 {
  top: 0px;
  left: 63px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links5 {
  top: 16px;
  left: 603px;
  width: 36px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text017 {
  top: 0px;
  left: 0px;
  width: 36px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-utility {
  top: 0px;
  left: 1203px;
  width: 237px;
  height: 56px;
  display: flex;
  padding: 16px 24px 16px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links6 {
  top: 16px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-search {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links7 {
  top: 16px;
  left: 48px;
  width: 165px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-navigation-links8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-person {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame1 {
  top: 0px;
  left: 24px;
  width: 117px;
  height: 24px;
  display: flex;
  padding: 2.5px 4px 2.5px 0;
  position: absolute;
  align-items: center;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text018 {
  top: 2.5px;
  color: var(--dl-color-gray-white);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-directionexpand5 {
  top: 0px;
  left: 141px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame51 {
  top: 419px;
  left: 142px;
  width: 1156px;
  height: 63px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-section1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 22px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext {
  height: 63px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text020 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  margin-right: 0;
  margin-bottom: 8px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text022 {
  color: var(--dl-color-global_gray-gray500);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame334 {
  top: 528px;
  left: 142px;
  width: 1150px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame54 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame511 {
  width: 1150px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 10px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext1 {
  width: 1038px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text024 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text026 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy1 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame541 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame512 {
  width: 1150px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 10px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext2 {
  width: 1038px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text028 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text030 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-content {
  display: flex;
  padding: 2px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-button {
  width: 94px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button-internal {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-edit {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 11px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frametextbox {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text032 {
  color: var(--dl-color-global_blue-blue500);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy2 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame542 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame513 {
  width: 1150px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 10px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext3 {
  width: 1038px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text034 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text036 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-content1 {
  display: flex;
  padding: 2px 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-button1 {
  width: 94px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button1 {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  align-items: center;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button-internal1 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-edit1 {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 11px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frametextbox1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text038 {
  color: var(--dl-color-global_blue-blue500);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy3 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame514 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-textandsupportingtext4 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-bottom: 15px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text040 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 26px;
  margin-right: 0;
  margin-bottom: 2px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text042 {
  color: var(--dl-color-global_gray-gray800);
  width: 891px;
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy4 {
  width: 1150px;
  height: 1px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame330 {
  top: 148px;
  left: 142px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame329 {
  width: 1155px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text044 {
  color: var(--dl-color-global_gray-gray800);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 38px;
  margin-right: 636px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame-button2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-parent-button2 {
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue500);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button-internal2 {
  display: flex;
  padding: 12px 22px;
  position: relative;
  align-items: center;
  border-color: transparent;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frametextbox2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text046 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: normal;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text048 {
  color: var(--dl-color-global_gray-gray500);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 31px;
  margin-right: 0;
  margin-bottom: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text-field1on-white1single-linewith-icona-inactive-copy {
  width: 440px;
  height: 68px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-bottom: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container1 {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: rgba(102, 103, 107, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-state {
  top: 0px;
  left: 0px;
  width: 440px;
  height: 48px;
  position: absolute;
  border-color: transparent;
  border-radius: 5px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-focus {
  top: -2px;
  left: -2px;
  width: 444px;
  height: 52px;
  position: absolute;
  border-color: transparent;
  border-radius: 7px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-icon {
  top: 12px;
  left: 404px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text050 {
  top: 21px;
  left: 12px;
  color: var(--dl-color-global_gray-gray800);
  width: 384px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text052 {
  top: 6px;
  left: 12px;
  color: rgba(52, 54, 59, 1);
  width: 384px;
  height: auto;
  position: absolute;
  font-size: 12px;
  align-self: auto;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 15px;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-group50 {
  width: 1155px;
  height: 42px;
  display: flex;
  padding: 0;
  position: relative;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tabs {
  top: 0px;
  left: 0px;
  width: 1155px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: center;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-scrollable-tab-text-elements {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 0px;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-content {
  display: flex;
  padding: 4px 16px 2px 16px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text054 {
  color: var(--dl-color-global_blue-blue500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-bar {
  width: auto;
  height: 4px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-scrollable-tab-text-elements1 {
  width: 577.5px;
  display: flex;
  padding: 6px 0 0 0;
  position: relative;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab1 {
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: transparent;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-content1 {
  display: flex;
  padding: 4px 16px 5px 16px;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text056 {
  color: var(--dl-color-global_gray-gray500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-tab-bar1 {
  width: auto;
  height: 1px;
  position: relative;
  align-self: stretch;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-footer {
  top: 911px;
  left: 0px;
  width: 1440px;
  height: 487px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-framebase-footer-legal {
  top: 429px;
  left: 0px;
  width: 1440px;
  display: flex;
  padding: 17px 0;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-global_blue-blue800);
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-utility-links {
  display: flex;
  padding: 0 70.68000030517578px;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame83 {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 18px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text058 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text060 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy23 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text062 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy231 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text064 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy232 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text066 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 17px;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-rectangle-copy233 {
  width: 1px;
  height: 14px;
  position: relative;
  border-color: rgba(150, 150, 150, 1);
  margin-right: 17px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text068 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame326 {
  width: 188px;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  justify-content: flex-end;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame831 {
  display: flex;
  position: relative;
  align-items: center;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-country {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 8px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-vector {
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text070 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: right;
  line-height: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-horizon {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 429px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-brand-horizon {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 429px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-brand-horizon1 {
  top: 4.901123046875px;
  left: 0px;
  width: 1440px;
  height: 424px;
  position: absolute;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-connect-with-us {
  top: 300px;
  left: -0.3400001525878906px;
  width: 203px;
  display: flex;
  padding: 0 0 0 70.68000030517578px;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text072 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-social-icons {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-facebook {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-twitter {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-facebook1 {
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 12px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-youtube {
  width: 24px;
  height: 24px;
  position: relative;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame9 {
  top: 200px;
  left: 797px;
  width: 555px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: transparent;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-about-hills {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 70px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text074 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text076 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text078 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 22px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text080 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-our-food-brands {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  margin-right: 70px;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text082 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text084 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text086 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text088 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text090 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-support {
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame327 {
  display: flex;
  padding: 0 8px 0 0;
  position: relative;
  align-items: flex-start;
  border-color: transparent;
  flex-direction: column;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text092 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text094 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text096 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 23px;
}
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text098 {
  color: var(--dl-color-gray-white);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 19px;
  margin-right: 0;
  margin-bottom: 0;
}

/* MANUALLY CUSTOM STYLES ADDED AFTER THIS LINE */
.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-text335 {
  color: var(--dl-color-global_blue-blue500);
  width: 183px;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 16px;
  margin-right: 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 0.875rem;
}

.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-container4 {
  display: flex;
  padding: 4px 16px 2px 16px;
  position: relative;
  align-items: center;
  border-color: transparent;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}

.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-button335 {
  background-color: transparent;
  border-color: transparent;
}

.p11440311h-g-m-home-adminmember-viewgroupdetailstabclon-frame335 {
  width: 100%;
  display: flex;
  justify-content: flex-end;

}
