.tm .container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tm .center-div {
  width: auto;
}

h1 {
  margin: 10px;
  font-size: 32px;
  line-height: 38px;
  color: #0054A4;
}

.tm .p1 {
  margin: 15px;
  font-size: 18px;
  line-height: 31px;
}

.tm .p2 {
  margin: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}